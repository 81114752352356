import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Table,
  Tabs,
  Form,
  Input,
  message,
  Spin,
  Dropdown,
  Checkbox,
} from "antd";

import history from "../../../history";
import { logout } from "../../../redux/user/thunks/logoutThunk";
import { emailExists, phoneExists } from "../../../redux/registration/thunks/registrationDataThunks";
import { resetUsersFilter, resetUsersList } from "../../../redux/users/actions/searchUsersActions";
import { searchUsers, searchAllUsers, getNotifyEnabled, setNotifyEnabled } from "../../../redux/users/thunks/searchUsersThunk";
import {
  getTags,
  renameTag,
  tagUsers,
  addTag,
  removeTag,
} from "../../../redux/tags/thunks/tagsThunks";
import { setUsersTablePagination } from "../../../redux/users/actions/tableActions";
import { createAdmin, sendActivationEmail, updateSettings } from "../../../redux/users/thunks/userPermissionsThunks";
import { handleLoading } from "../../../utils/handleLoading";
import { formatDate } from "../../../utils/formatDate";
import { getMediaFileFromServer } from "../../../utils/getMediaFileFromServer";
import {
  emailRegExp,
  specialCharacterRegExp,
  upperCaseCharacterRegExp
} from "../../../utils/regexps";

import Button from "../../../components/Button/Button";
import Icon from "../../../components/Icon/Icon";
import PopUp from "../../../components/PopUp/PopUp";
import UsersFilter from "./UsersFilter";
import "./style.scss";

const { TabPane } = Tabs;

class AdminUsers extends Component {
  constructor(props) {
    super(props);

    this.usersColumns = [
      {
        title: "Name Surname",
        dataIndex: "nameSurname",
        key: "nameSurname",
        render: text => (
          <div className="flex-container align-middle">
            <span className="radius-circle clip flex-container align-middle align-center user_ava">
              {
                text[0] ?
                  <img src={getMediaFileFromServer(text[0])} alt="user-avatar" />
                  :
                  <div className="text text-15 font-heavy font-spacing-02 color-blue">
                    {text[1].charAt(0)}
                  </div>
              }
            </span>
            <div className="flex-container flex-dir-column" >
              <span className="user_name font-semibold color-grey">{text[1]}</span>
              <span>{text[2]}</span>
            </div>
          </div>
        ),
        sorter: (a, b) => a.nameSurname[1].localeCompare(b.nameSurname[1]),
      },
      {
        title: "User",
        dataIndex: "user",
        key: "user",
        sorter: (a, b) => a.user.localeCompare(b.user),
      },
      {
        title: "Reg date",
        dataIndex: "regDate",
        key: "regDate",
        sorter: (a, b) => moment(a.regDate).unix() - moment(b.regDate).unix(),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: payload => {
          if (payload.role !== "ADMIN") {
            const onboardedClassName = payload.text === "ONBOARDED" ? "imp_tag_lightgreen" : payload.text === "INACTIVE" ? 'imp_tag_grey' : "imp_tag_grey-light";
            return <div className={`imp_tag font-uppercase color-grey ${onboardedClassName}`}>{payload.text}</div>;
          }
        },
        sorter: (a, b) => a.status.text.localeCompare(b.status.text),
      },
      // {
      //   title: this.getSuppressMarkup,
      //   dataIndex: "notifications",
      //   className: 'custom-th',
      //   key: "notifications",
      //   render: ({ onChange, defaultValue }) => {
      //     const options = [
      //       { label: 'Notify Investments', value: 'notify_investments' },
      //       { label: 'Notify Reports', value: 'notify_reports' },
      //     ];

      //     return <div onClick={(event) => event.stopPropagation()}>
      //       <Checkbox.Group options={options} onChange={onChange} defaultValue={defaultValue} />
      //     </div>
      //   },
      // },
      {
        title: this.getSuppressMarkup,
        dataIndex: "actions",
        className: 'custom-th',
        key: "actions",
        render: ({ sendMessage, isActivated, onClick, logout }) => (
          <div onClick={(event) => event.stopPropagation()} >
            <Dropdown
              overlay={
                <div className="bg-white radius-4 shadow-layout drop_menu" >
                  <div
                    className="drop_menu_item cursor"
                    onClick={() => history.push(sendMessage)}
                  >
                    <i className="icon icon-letter" />
                    <span className="text text-14 font-medium font-uppercase font-spacing-04 color-grey">
                      Write Message
                    </span>
                  </div>

                  {
                    !isActivated ?
                      <div className="drop_menu_item cursor" onClick={onClick} >
                        <i className="icon icon-letter" />
                        <span className="text text-14 font-medium font-uppercase font-spacing-04 color-grey">
                          Send Activation Message
                        </span>
                      </div>
                      : null
                  }

                  {/* <div
                    onClick={logout}
                    className="drop_menu_item cursor"
                  >
                    <i className="icon icon-logout" />
                    <span className="text text-14 font-medium font-uppercase font-spacing-04 color-grey">
                      Log out
                    </span>
                  </div> */}
                </div>
              }
              trigger={['click']}
              placement="bottomRight"
            >
              <div className="icon_btn">
                <Button
                  title={<Icon type="letter" style={{ fontSize: "19px" }} />}
                  size="lg"
                  theme="lightblue"
                  className="radius-10"
                />
              </div>
            </Dropdown>
          </div>
        ),
      },
    ];
    this.groupsColumns = [
      {
        title: 'Name of GROUP',
        dataIndex: 'nameOfGroup',
        key: 'nameOfGroup',
        render: (text) => (
          <div className="flex-container align-middle">
            <span className="radius-circle clip flex-container align-middle align-center user_ava">
              <span className="text text-15 font-heavy font-spacing-02 color-blue">
                {text.charAt(0)}
              </span>
            </span>
            <span className="text-16 font-semibold color-grey">{text}</span>
          </div>
        ),
        sorter: (a, b) => a.nameOfGroup.localeCompare(b.nameOfGroup),
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        render: ({ id, value }) => (
          <div className="flex-container align-middle large-align-right icon_btn_group">
            {/* <Dropdown
              overlay={
                <div className="bg-white radius-4 shadow-layout drop_menu">
                  <div
                    className="drop_menu_item cursor"
                    onClick={() => {
                      this.handleModalWindow('renameGroupModal', true);
                      this.setRenameInputData({ id, value });
                    }}
                  >
                    <Icon type="pen" />
                    <span className="text text-14 font-medium font-uppercase font-spacing-04 color-grey">
                      rename
                    </span>
                  </div>
                  <Link to={`/admin-group-users/${id}`} className="drop_menu_item">
                    <Icon type="eye-on" className="color-grey" />
                    <span className="text text-14 font-medium font-uppercase font-spacing-04 color-grey">
                      view group
                    </span>
                  </Link>
                </div>
              }
              trigger={['click']}
              placement="bottomRight"
            >
            </Dropdown> */}
            <div className="icon_btn">
              <Button
                title={<Icon type="eye-on" style={{ fontSize: "24px" }} />}
                size="lg"
                theme="lightblue"
                className="radius-10"
                linkTo={`/admin-group-users/${id}`}
                onClick={(event) => event.stopPropagation()}
              />
            </div>
            <div className="icon_btn">
              <Button
                title={<Icon type="pen" style={{ fontSize: "19px" }} />}
                size="lg"
                theme="lightblue"
                className="radius-10"
                onClick={(event) => {
                  event.stopPropagation();
                  this.handleModalWindow('renameGroupModal', true);
                  this.setRenameInputData({ id, value });
                }}
              />
            </div>
            <div className="icon_btn">
              <Button
                title={<Icon type="bin" style={{ fontSize: "19px" }} />}
                size="lg"
                theme="lightblue"
                className="radius-10"
                onClick={(event) => {
                  event.stopPropagation();
                  this.handleDeleteActionBtn(id);
                }}
              />
            </div>
          </div>
        ),
      },
    ];
    this.state = {
      activeTab: "user",
      selectedRowKeys: [],
      selectedRowKeysSent: [],
      renameInputID: null,
      renameInitialValue: "",
      removingGroupID: null,
      messageWindow: false,
      userDeleteModal: false,
      newUserModal: false,
      addToGroupModal: false,
      newGroupModal: false,
      renameGroupModal: false,
      validation8Chars: {
        isFilled: false,
        isValid: false,
      },
      validationSpecialCharacter: {
        isFilled: false,
        isValid: false,
      },
      validationUpperCase: {
        isFilled: false,
        isValid: false,
      },
    }
  }

  handleModalWindow = (name, value) => {
    this.setState({ [name]: value });
  };

  cancelRenameModal = () => {
    this.handleModalWindow('renameGroupModal', false);
    this.setRenameInputData({ id: null, value: "" });
    this.props.form.resetFields();
  };

  setRenameInputData = ({ id, value }) => {
    this.setState({
      renameInputID: id,
      renameInitialValue: value,
    });
  };

  handleRenameOnClick = () => {
    const { renameInputID } = this.state;
    const { renameTag, form } = this.props;
    const name = form.getFieldValue("renameGroupField");
    renameTag({ id: renameInputID, name });
  };

  handleRowClick = (userID) => {
    history.push(`/admin-users-inner/${userID}`);
  };

  handleGroupsRowClick = (groupID) => {
    history.push(`/admin-group-users/${groupID}`);
  };

  onNotifyChange = (values, id) => {
    const { updateSettings } = this.props;

    const params = {
      id,
      notify_investments: values.includes("notify_investments") ? 1 : 0,
      notify_reports: values.includes("notify_reports") ? 1 : 0,
    };

    updateSettings(params);
  };

  onSuppressChange = (e) => {
    const { setNotifyEnabled } = this.props;

    setNotifyEnabled(e.target.checked ? 0 : 1);
  };

  getSuppressMarkup = () => {
    const { notifyEnabled } = this.props;
    return <Checkbox checked={notifyEnabled} onChange={this.onSuppressChange}>Suppress notifications</Checkbox>
  }

  getDefaultValues = (element) => {
    let values = [];

    if (element.notify_investments) {
      values = [...values, "notify_investments"];
    }
    if (element.notify_reports) {
      values = [...values, "notify_reports"];
    }

    return values;
  }

  getUsersList = () => {
    const { usersList } = this.props;
    let returningUsersList = [];

    if (usersList) {
      const { sendActivationEmail, logout } = this.props;

      usersList.map(element => {
        return returningUsersList.push({
          key: element.id,
          nameSurname: [element.avatar, `${element.first_name} ${element.last_name}`, element.email],
          user: element.role,
          regDate: formatDate(element.created),
          status: {
            role: element.role,
            text: parseInt(element.activated) === 0 ? "INACTIVE" : parseInt(element.onboarded) === 0 ? "NOT ONBOARDED" : "ONBOARDED",
          },
          // notifications: {
          //   onChange: (e) => this.onNotifyChange(e, element.id),
          //   defaultValue: this.getDefaultValues(element),
          // },
          actions: {
            sendMessage: `/contact-center/${element.id}/${element.first_name} ${element.last_name}/user`,
            isActivated: parseInt(element.activated) === 1,
            onClick: () => sendActivationEmail(element.id),
            logout: () => logout(element.id),
          },
        });
      });
    }

    return returningUsersList;
  };

  getGroupsList = () => {
    const { tagsList } = this.props;
    let returningGroupsList = [];
    

    if (tagsList) {
      tagsList.map(element => {
        return returningGroupsList.push({
          key: element.id,
          nameOfGroup: element.name,
          actions: {
            id: element.id,
            value: element.name,
          },
        })
      });
    }

    return returningGroupsList;
  };

  infiniteScroll = () => {
    if ((Math.ceil(window.innerHeight + window.scrollY)) >= document.body.offsetHeight) {
      const { totalUsers, pagination } = this.props;
      const { currentPage } = pagination;
      const roundedCount = Math.ceil(totalUsers / 10) * 10;
      const checkingNumber = currentPage * 10;

      if (roundedCount >= checkingNumber) { // for avoiding of calling endless searchUsers() thunk
        const { searchUsers } = this.props;
        searchUsers(currentPage);
      }
    }
  };

  handleTabsOnChange = (tabName) => {
    this.setState({ activeTab: tabName });
  };

  addUsersToAnExistingGroup = (tag) => {
    const { tagUsers } = this.props;
    const { selectedRowKeys } = this.state;
    tagUsers({ tag, users: selectedRowKeys });
  };

  addUsersToANewGroup = () => {
    const { form, addTag } = this.props;
    const { selectedRowKeys } = this.state;
    const name = form.getFieldValue("tagName");
    addTag(name, selectedRowKeys);
  };

  handleDeleteActionBtn = (removingGroupID) => {
    this.setState({ removingGroupID }, () => {
      this.handleModalWindow('userDeleteModal', true);
    });
  };

  handleDeleteTheGroup = () => {
    const { removeTag } = this.props;
    const { removingGroupID } = this.state;
    removeTag(removingGroupID);
  };

  validateEmailField = (doesEmailExist) => {
    const { form } = this.props;

    if (doesEmailExist) {
      form.setFields({
        email: {
          value: form.getFieldValue("email"),
          errors: [new Error("Duplicate email")]
        }
      });
    }
  };

  validatePhoneField = (doesPhoneExist) => {
    const { form } = this.props;

    if (doesPhoneExist) {
      form.setFields({
        phone: {
          value: form.getFieldValue("phone"),
          errors: [new Error("Duplicate phone")]
        }
      });
    }
  };

  emailOnBlur = (event) => {
    const { value } = event.target;
    const { emailExists, form } = this.props;

    if (value) {
      if (emailRegExp.test(value)) {
        emailExists(value);
      }
      else {
        form.setFields({
          email: {
            value,
            errors: [new Error("Invalid email")]
          }
        });
      }
    }
  };

  phoneOnBlur = e => {
    const { value } = e.target;
    const { phoneExists } = this.props;

    if (value) {
      phoneExists(value);
    }
  };

  passwordValidator = async (rule, value) => {
    if (value) {
      let isValid = true;
      if (value.length < 8) {
        this.setState({
          validation8Chars: {
            isFilled: true,
            isValid: false,
          },
        });
        isValid = false;
      } else {
        this.setState({
          validation8Chars: {
            isFilled: true,
            isValid: true,
          },
        });
      }
      if (!specialCharacterRegExp.test(value)) {
        this.setState({
          validationSpecialCharacter: {
            isFilled: true,
            isValid: false,
          },
        });
        isValid = false;
      } else {
        this.setState({
          validationSpecialCharacter: {
            isFilled: true,
            isValid: true,
          },
        });
      }
      if (!upperCaseCharacterRegExp.test(value)) {
        this.setState({
          validationUpperCase: {
            isFilled: true,
            isValid: false,
          },
        });
        isValid = false;
      } else {
        this.setState({
          validationUpperCase: {
            isFilled: true,
            isValid: true,
          },
        });
      }

      if (!isValid) {
        return Promise.reject('');
      }
    } else {
      this.setState({
        validation8Chars: {
          isFilled: false,
          isValid: false,
        },
        validationSpecialCharacter: {
          isFilled: false,
          isValid: false,
        },
        validationUpperCase: {
          isFilled: false,
          isValid: false,
        },
      });
    }
  };

  onSubmit = (event) => {
    event.preventDefault();

    const validatingFields = [
      'first_name',
      'last_name',
      'email',
      'password',
      'phone',
      'access_investments',
      'write_investments',
      'access_portfolio',
      'write_portfolio',
      'access_messages',
      'write_messages',
      'access_files',
      'write_files',
      'access_accounts',
      'write_accounts',
    ];
    this.props.form.validateFieldsAndScroll(validatingFields, (err, values) => {
      if (!err) {
        const { emailExists, phoneExists, createAdmin } = this.props;
        values.first_name = values.first_name ? values.first_name : "";
        values.last_name = values.last_name ? values.last_name : "";
        values.email = values.email ? values.email : "";
        values.password = values.password ? values.password : "";
        values.phone = values.phone ? values.phone.replace(/\D/g, '') : "";
        values.access_investments = values.access_investments ? 1 : 0;
        values.write_investments = values.write_investments ? 1 : 0;
        values.access_portfolio = values.access_portfolio ? 1 : 0;
        values.write_portfolio = values.write_portfolio ? 1 : 0;
        values.access_messages = values.access_messages ? 1 : 0;
        values.write_messages = values.write_messages ? 1 : 0;
        values.access_files = values.access_files ? 1 : 0;
        values.write_files = values.write_files ? 1 : 0;
        values.access_accounts = values.access_accounts ? 1 : 0;
        values.write_accounts = values.write_accounts ? 1 : 0;

        emailExists(values.email, (doesEmailExists) => {
          phoneExists(values.phone, (doesPhoneExists) => {
            if (doesEmailExists) {
              this.validateEmailField(true);
            }
            if (doesPhoneExists) {
              this.validatePhoneField(true);
            }
            else if (!doesPhoneExists && !doesPhoneExists) {
              createAdmin(values);
            }
          });
        });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      loading,
      tagsLoading,
      errorMessage,
      tagsErrorMessage,
      userLoading,
      userErrorMessage,
      form,
      regLoading,
      regErrorMessage,
      doesEmailExist,
      doesPhoneExist,
    } = this.props;
    const loadingType = loading.type;
    const loadingState = loading.state;
    const errorText = errorMessage.message;
    const tagsLoadingType = tagsLoading.type;
    const tagsLoadingState = tagsLoading.state;
    const tagsErrorText = tagsErrorMessage.message;
    const regLoadingType = regLoading.type;
    const regLoadingState = regLoading.state;
    const regErrorText = regErrorMessage.message;
    const userLoadingType = userLoading.type;
    const userLoadingState = userLoading.state;
    const userErrorText = userErrorMessage.messages;

    if (!errorText && !loadingState && prevProps.loading.state && loadingType === "SendActivationEmail") {
      message.success("Activation link sent");
    }

    if (!tagsErrorText && !tagsLoadingState && prevProps.tagsLoading.state) {
      switch (tagsLoadingType) {
        case "RenameTag":
          this.setState({
            renameGroupModal: false,
            renameInputID: null,
          }, () => {
            form.resetFields();
          });
          break;
        case "TagUsers":
          this.setState({
            selectedRowKeys: [],
          }, () => {
            this.handleModalWindow('addToGroupModal', false);
          });
          break;
        case "AddTag":
          this.setState({
            selectedRowKeys: [],
            newGroupModal: false,
            addToGroupModal: false,
          }, () => {
            form.resetFields();
          });
          break;
        case "RemoveTag":
          this.setState({
            userDeleteModal: false,
            removingGroupID: null,
          });
          break;
        default:
      }
    }

    if (!errorText && !loadingState && prevProps.loading.state) {
      if (loadingType === 'CreateAdmin') {
        message.success("Admin has been added successfully");
        this.props.form.resetFields();
        this.handleModalWindow('newUserModal', false);
      }
    }

    if (!regErrorText && !regLoadingState && prevProps.regLoading.state) {
      switch (regLoadingType) {
        case "EmailExists":
          this.validateEmailField(doesEmailExist);
          break;
        case "PhoneExists":
          this.validatePhoneField(doesPhoneExist);
          break;
        default:
      }
    }

    if (!userErrorText && !userLoadingState && prevProps.userLoading.state && userLoadingType === 'Logout') {
      message.success('Logged out successfully');
    }
  }

  componentWillUnmount() {
    this.props.resetUsersFilter();
    this.props.resetUsersList();
    window.removeEventListener("scroll", this.infiniteScroll);
  }

  componentDidMount() {
    const {
      searchUsers,
      getTags,
      getNotifyEnabled,
      filter,
    } = this.props;

    searchUsers(1, filter);
    window.addEventListener("scroll", this.infiniteScroll);

    getTags();
    getNotifyEnabled();
  }

  render() {
    const {
      form,
      totalUsers,
      loading,
      tagsLoading,
      tagsList,
      userInfo,
    } = this.props;

    const {
      selectedRowKeys,
      userDeleteModal,
      newUserModal,
      addToGroupModal,
      newGroupModal,
      renameGroupModal,
      activeTab,
      renameInitialValue,
      validation8Chars,
      validationSpecialCharacter,
      validationUpperCase,
    } = this.state;

    const { getFieldDecorator, resetFields } = form;
    const usersList = this.getUsersList();
    const groupsList = this.getGroupsList();
    const hasSelected = selectedRowKeys.length > 0;
    const usersRowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User", // Column configuration not to be checked
        name: record.name,
      }),
    };

    const usersOperationsSlot = (
      <div className="flex-container align-middle">
        <div className="flex-container align-middle table_action">
          <Button
            title={'View All'}
            size="lg"
            theme="lightblue"
            mode="bordered"
            className="radius-8 mb_10"
            type="button"
            disabled={usersList.length === totalUsers}
            onClick={() => {
              if (usersList.length !== totalUsers) {
                const { searchAllUsers } = this.props;

                searchAllUsers();
                window.removeEventListener("scroll", this.infiniteScroll);
              }
            }}
          />
          {hasSelected && <div className="text font-semibold color-blue">
            Selected {selectedRowKeys.length} user
          </div>}
          <Button
            title={hasSelected ? "Add To Group" : `Add All Users To Group  (${totalUsers})`}
            size="lg"
            theme="lightblue"
            mode="bordered"
            className="radius-8 mb_10"
            type="button"
            onClick={() => {
              this.handleModalWindow("addToGroupModal", true);
            }}
          />
        </div>
        {
          !!userInfo?.write_accounts &&
          <Button
            title="Create New Admin"
            prefix={<Icon type="plus" size={18} style={{ marginRight: 10 }} />}
            size="lg"
            theme="blue"
            className="btn-shadow radius-8 mb_10 add_message sm_circle"
            type="button"
            disabled={hasSelected}
            onClick={() => {
              this.handleModalWindow("newUserModal", true);
            }}
          />
        }
      </div>
    );

    return (
      <div className="relative fluid-y screen_wrapper">
        <div className="row">
          <div className="column small-12">
            <div className="page_top page_top-tall">
              <div className="page_title visible-first custom-headline text text-28 text-height-48 font-heavy font-uppercase color-grey">
                <h1>Users</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="column small-12">
            <UsersFilter />
            <Tabs
              defaultActiveKey={activeTab}
              className="c-table tab_default contact_tab tab_sticky users-tab"
              tabBarExtraContent={activeTab === "user" ? usersOperationsSlot : null}
              onChange={this.handleTabsOnChange}
            >
              <TabPane
                tab={
                  <div className="flex-container align-middle">
                    <Icon type="user" size={25} style={{ marginRight: 6 }} />
                    Users
                  </div>
                }
                key="user"
              >
                <Spin spinning={handleLoading(loading, "SearchUsers") || handleLoading(loading, "SearchAllUsers")}>
                  <Table
                    rowSelection={usersRowSelection}
                    columns={this.usersColumns}
                    dataSource={usersList}
                    className="c-table c-table-bordered users-table"
                    onRow={({ key }) => ({ onClick: () => this.handleRowClick(key) })}
                    pagination={false}
                  />
                </Spin>
              </TabPane>
              <TabPane
                tab={
                  <div className="flex-container align-middle">
                    <Icon type="group" size={20} style={{ marginRight: 9 }} />
                    Groups
                  </div>
                }
                key="groups"
              >
                <div className="contact_tables">
                  <Table
                    columns={this.groupsColumns}
                    dataSource={groupsList}
                    pagination={false}
                    className="c-table c-table-bordered hide_paging users_table"
                    onRow={({ key }) => ({ onClick: () => this.handleGroupsRowClick(key) })}
                    onChange={this.handleTableChange}
                  />
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>

        <PopUp
          className="relative_parent"
          visible={userDeleteModal}
          innerClassNames="shadow-layout bg-white radius-10 relative sm base"
        >
          <div className="popup_icon">
            <img src={require("../../../assets/img/delete.svg")} alt="" />
          </div>
          <div className="text-center">
            <div className="text text-28 text-height-30 font-heavy font-spacing-02 color-grey font-uppercase mb_35">
              Do you want to delete this group?
            </div>

            <div className="btn_group">
              <Button
                title="Cancel"
                size="lg"
                theme="lightblue"
                mode="bordered"
                className="radius-8"
                style={{ fontSize: 18 }}
                onClick={() => {
                  this.handleModalWindow('userDeleteModal', false)
                }}
              />
              <Button
                title="Delete"
                type="button"
                prefix={<Icon type="bin" style={{ fontSize: "18px", marginRight: 12 }} />}
                size="lg"
                theme="lightred"
                mode="bordered"
                className="radius-8"
                style={{ fontSize: 18 }}
                onClick={this.handleDeleteTheGroup}
                loading={handleLoading(tagsLoading, "RemoveTag")}
              />
            </div>
          </div>
        </PopUp>

        <PopUp
          className="relative_parent"
          visible={newUserModal}
          innerClassNames="shadow-layout bg-white radius-10 relative md base"
        >
          <div
            className="popup_close color-grey-700"
            onClick={() => {
              this.handleModalWindow('newUserModal', false)
            }}
          >
            <Icon type="cancel" size={24} />
          </div>
          <div className="popup-form">
            <Form onSubmit={this.onSubmit} >
              <div className="flex-container align-center text-center">
                <div className="text text-28 text-height-33 font-heavy font-spacing-02 color-grey font-uppercase large-7 mb_45">
                  Create new admin
                </div>
              </div>
              <div className="mb_25">
                <div className="row">
                  <div className="column small-12">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold font-uppercase color-grey">
                        First Name
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator('first_name', {
                          initialValue: "",
                          rules: [{ required: true, message: 'Please fill out this field' }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold font-uppercase color-grey">
                        Last Name
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator('last_name', {
                          initialValue: "",
                          rules: [{ required: true, message: 'Please fill out this field' }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold font-uppercase color-grey">
                        Email
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator('email', {
                          initialValue: "",
                          rules: [{ required: true, message: 'Please fill out this field' }],
                        })(
                          <Input onBlur={this.emailOnBlur} type='email' />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="column small-12">
                    <div className="form-item">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        PASSWORD
                      </div>
                      <Form.Item>
                        {getFieldDecorator("password", {
                          initialValue: '',
                          rules: [
                            { required: true, message: "Please fill out this field" },
                            { validator: this.passwordValidator },
                          ],
                          validateTrigger: "onBlur"
                        })(
                          <Input.Password />
                        )}
                      </Form.Item>
                      <div className="validators-wrapper" >
                        <p
                          className="mb_0"
                          style={validation8Chars.isFilled && validation8Chars.isValid ? { color: 'green' } : null}
                        >
                          <Icon
                            type='checked'
                            style={validation8Chars.isFilled && validation8Chars.isValid ? { color: 'green' } : null}
                          />
                          At least 8 character
                        </p>
                        <p
                          className="mb_0"
                          style={validationSpecialCharacter.isFilled && validationSpecialCharacter.isValid ? { color: 'green' } : null}
                        >
                          <Icon
                            type='checked'
                            style={validationSpecialCharacter.isFilled && validationSpecialCharacter.isValid ? { color: 'green' } : null}
                          />
                          At least 1 special character
                        </p>
                        <p
                          className="mb_0"
                          style={validationUpperCase.isFilled && validationUpperCase.isValid ? { color: 'green' } : null}
                        >
                          <Icon
                            type='checked'
                            style={validationUpperCase.isFilled && validationUpperCase.isValid ? { color: 'green' } : null}
                          />
                          At least 1 upper case character
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="column small-12">
                    <div className="form-item mb_40">
                      <div className="form-item-label text text-height-20 font-semibold font-uppercase color-grey">
                        Phone
                      </div>
                      <Form.Item className="mb_0">
                        {getFieldDecorator('phone', {
                          initialValue: "",
                          rules: [{ required: true, message: 'Please fill out this field' }],
                        })(
                          <Input onBlur={this.phoneOnBlur} />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="text text-22 font-bold color-grey mb_25">
                    Choose accessibility for admin
                  </div>

                  <div className="user_access_group relative separator">
                    <div className="flex-container" >
                      <div className="view-items-wrapper" >
                        <div className="text text-14 font-medium color-grey-800 font-uppercase font-spacing-04 mb_25">
                          View
                        </div>
                        <div className="form-item mb_15">
                          <Form.Item className="mb_0">
                            {getFieldDecorator("access_investments", {
                              valuePropName: "checked",
                              initialValue: false,
                              rules: [{ required: false, message: "Please fill out this field" }]
                            })(
                              <Checkbox>Investment Opportunities</Checkbox>
                            )}
                          </Form.Item>
                        </div>
                        <div className="form-item mb_15">
                          <Form.Item className="mb_0">
                            {getFieldDecorator("access_portfolio", {
                              valuePropName: "checked",
                              initialValue: false,
                              rules: [{ required: false, message: "Please fill out this field" }]
                            })(
                              <Checkbox>My Portfolio</Checkbox>
                            )}
                          </Form.Item>
                        </div>
                        <div className="form-item mb_15">
                          <Form.Item className="mb_0">
                            {getFieldDecorator("access_messages", {
                              valuePropName: "checked",
                              initialValue: false,
                              rules: [{ required: false, message: "Please fill out this field" }]
                            })(
                              <Checkbox>Contact Center</Checkbox>
                            )}
                          </Form.Item>
                        </div>
                        <div className="form-item mb_15">
                          <Form.Item className="mb_0">
                            {getFieldDecorator("access_files", {
                              valuePropName: "checked",
                              initialValue: false,
                              rules: [{ required: false, message: "Please fill out this field" }]
                            })(
                              <Checkbox>Data Room</Checkbox>
                            )}
                          </Form.Item>
                        </div>
                        <div className="form-item mb_15">
                          <Form.Item className="mb_0">
                            {getFieldDecorator("access_accounts", {
                              valuePropName: "checked",
                              initialValue: false,
                              rules: [{ required: false, message: "Please fill out this field" }]
                            })(
                              <Checkbox>Users</Checkbox>
                            )}
                          </Form.Item>
                        </div>
                      </div>

                      <div className="edit-items-wrapper" >
                        <div className="text text-14 font-medium color-grey-800 font-uppercase font-spacing-04 mb_25">
                          Edit
                        </div>
                        <div className="form-item mb_15">
                          <Form.Item className="mb_0">
                            {getFieldDecorator("write_investments", {
                              valuePropName: "checked",
                              initialValue: false,
                              rules: [{ required: false, message: "Please fill out this field" }]
                            })(
                              <Checkbox>Investment Opportunities</Checkbox>
                            )}
                          </Form.Item>
                        </div>
                        <div className="form-item mb_15">
                          <Form.Item className="mb_0">
                            {getFieldDecorator("write_portfolio", {
                              valuePropName: "checked",
                              initialValue: false,
                              rules: [{ required: false, message: "Please fill out this field" }]
                            })(
                              <Checkbox>My Portfolio</Checkbox>
                            )}
                          </Form.Item>
                        </div>
                        <div className="form-item mb_15">
                          <Form.Item className="mb_0">
                            {getFieldDecorator("write_messages", {
                              valuePropName: "checked",
                              initialValue: false,
                              rules: [{ required: false, message: "Please fill out this field" }]
                            })(
                              <Checkbox>Contact Center</Checkbox>
                            )}
                          </Form.Item>
                        </div>
                        <div className="form-item mb_15">
                          <Form.Item className="mb_0">
                            {getFieldDecorator("write_files", {
                              valuePropName: "checked",
                              initialValue: false,
                              rules: [{ required: false, message: "Please fill out this field" }]
                            })(
                              <Checkbox>Data Room</Checkbox>
                            )}
                          </Form.Item>
                        </div>
                        <div className="form-item mb_15">
                          <Form.Item className="mb_0">
                            {getFieldDecorator("write_accounts", {
                              valuePropName: "checked",
                              initialValue: false,
                              rules: [{ required: false, message: "Please fill out this field" }]
                            })(
                              <Checkbox>Users</Checkbox>
                            )}
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <Button
                  title="Create Admin"
                  size="xl"
                  type='submit'
                  theme="blue"
                  prefix={<Icon type="plus" size={18} style={{ marginRight: 10 }} />}
                  className="radius-8"
                  loading={handleLoading(loading, 'CreateAdmin')}
                  style={{ fontSize: 18 }}
                />
              </div>
            </Form>
          </div>
        </PopUp>

        <PopUp
          className="relative_parent"
          visible={addToGroupModal}
          innerClassNames="shadow-layout bg-white radius-10 relative md base"
        >
          <div
            className="popup_close color-grey-700"
            onClick={() => this.handleModalWindow('addToGroupModal', false)}
          >
            <Icon type="cancel" size={24} />
          </div>
          <Spin spinning={handleLoading(tagsLoading, "TagUsers")}>
            <div>
              <div className="medium-flex-container align-middle align-justify mb_30">
                <div className="text text-20 font-bold color-grey">
                  Select group
                </div>
                <Button
                  title="Create new"
                  size="lg"
                  theme="lightblue"
                  mode="bordered"
                  prefix={<Icon type="plus" size={14} style={{ marginRight: 10 }} />}
                  className="radius-8"
                  style={{ fontSize: 18 }}
                  onClick={() => {
                    this.handleModalWindow('newGroupModal', true)
                  }}
                />
              </div>
              <div>
                {
                  tagsList && tagsList.map((element) => (
                    <div key={element.id} onClick={() => this.addUsersToAnExistingGroup(element.id)} className="flex-container align-middle align-justify group_item user_item_panel">
                      <div className="flex-container align-middle user_item">
                        <div className="radius-circle clip bg-lightblue flex-container align-middle align-center user_item__img">
                          <div className="text text-20 font-bold color-blue">{element.name.charAt(0)}</div>
                        </div>
                        <div className="text font-semibold color-grey">{element.name}</div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </Spin>
        </PopUp>

        <PopUp
          className="relative_parent"
          visible={newGroupModal}
          innerClassNames="shadow-layout bg-white radius-10 relative md base"
        >
          <div
            className="popup_close color-grey-700"
            onClick={() => {
              this.handleModalWindow('newGroupModal', false)
            }}
          >
            <Icon type="cancel" size={24} />
          </div>
          <div>
            <div className="text text-20 text-height-33 font-bold color-grey mb_30">
              New Group &nbsp;
              <span className="text-14 font-medium color-grey-900">
                (Selected {selectedRowKeys.length ? selectedRowKeys.length : totalUsers} user)
              </span>
            </div>
            <div className="form-item mb_40">
              <Form.Item className="mb_0">
                {getFieldDecorator('tagName', {
                  initialValue: "",
                  rules: [{ required: true, message: 'Please fill out this field' }],
                })(
                  <Input />
                )}
              </Form.Item>
            </div>
            <div className="btn_group text-right">
              <Button
                title="Cancel"
                size="lg"
                theme="default"
                className="btn-link radius-8 font-uppercase"
                onClick={() => {
                  this.handleModalWindow('newGroupModal', false);
                  resetFields();
                }}
              />
              <Button
                title="Create"
                type="button"
                size="lg"
                theme="blue"
                className="radius-8"
                onClick={this.addUsersToANewGroup}
                loading={handleLoading(tagsLoading, "AddTag")}
              />
            </div>
          </div>
        </PopUp>

        <PopUp
          className="relative_parent"
          visible={renameGroupModal}
          innerClassNames="shadow-layout bg-white radius-10 relative md base"
        >
          <div
            className="popup_close color-grey-700"
            onClick={this.cancelRenameModal}
          >
            <Icon type="cancel" size={24} />
          </div>
          <div>
            <div className="text text-20 text-height-33 font-bold color-grey mb_30">
              Rename group
            </div>
            <div className="form-item mb_40">
              <Form.Item className="mb_0">
                {getFieldDecorator('renameGroupField', {
                  initialValue: renameInitialValue,
                  rules: [{ required: true, message: 'Please fill out this field' }],
                })(
                  <Input />
                )}
              </Form.Item>
            </div>
            <div className="btn_group text-right">
              <Button
                title="Cancel"
                size="lg"
                theme="default"
                className="btn-link radius-8 font-uppercase"
                onClick={this.cancelRenameModal}
              />
              <Button
                title="Rename"
                size="lg"
                theme="blue"
                type="button"
                className="radius-8"
                onClick={this.handleRenameOnClick}
                loading={handleLoading(tagsLoading, "RenameTag")}
              />
            </div>
          </div>
        </PopUp>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  xID: state.user.xID,
  loading: state.users.loading,
  tagsLoading: state.tags.loading,
  userLoading: state.user.loading,
  userErrorMessage: state.user.errorMessage,
  errorMessage: state.users.errorMessage,
  tagsErrorMessage: state.tags.errorMessage,
  regLoading: state.registration.loading,
  regErrorMessage: state.registration.errorMessage,
  usersList: state.users.usersList,
  tagsList: state.tags.tagsList,
  pagination: state.users.pagination,
  totalPages: state.users.totalPages,
  totalUsers: state.users.totalUsers,
  doesEmailExist: state.registration.emailExists,
  doesPhoneExist: state.registration.phoneExists,
  filter: state.users.filter,
  userInfo: state.user.userInfo,
  notifyEnabled: state.users.notifyEnabled,
});

const mapDispatchToProps = dispatch => ({
  searchUsers: (currentPage, filter, groupData) => dispatch(searchUsers(currentPage, filter, groupData)),
  searchAllUsers: () => dispatch(searchAllUsers()),
  getTags: () => dispatch(getTags()),
  renameTag: params => dispatch(renameTag(params)),
  tagUsers: params => dispatch(tagUsers(params)),
  addTag: (name, usersList) => dispatch(addTag(name, usersList)),
  removeTag: (id) => dispatch(removeTag(id)),
  sendActivationEmail: id => dispatch(sendActivationEmail(id)),
  setUsersTablePagination: payload => dispatch(setUsersTablePagination(payload)),
  emailExists: (email, callback) => dispatch(emailExists(email, callback)),
  phoneExists: (phone, callback) => dispatch(phoneExists(phone, callback)),
  createAdmin: (params) => dispatch(createAdmin(params)),
  logout: (id) => dispatch(logout(id, true)),
  resetUsersFilter: () => dispatch(resetUsersFilter()),
  resetUsersList: () => dispatch(resetUsersList()),
  updateSettings: params => dispatch(updateSettings(params)),
  getNotifyEnabled: () => dispatch(getNotifyEnabled()),
  setNotifyEnabled: (enabled) => dispatch(setNotifyEnabled(enabled)),
});

const AdminUsersScreen = Form.create({ name: "adminUsers" })(AdminUsers);

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersScreen);
